import * as React from "react";
import SearchSuggestionBox from "../../search-suggestion-box";
import { Icon } from "@blueprintjs/core";
import GenomeTile from "../../genome-tile";
import { performSearch } from "../../../utils/search";

class ATCCHomePage extends React.PureComponent {
  renderGenomeTiles = () => {
    const genomes = [
      {
        name: "Spirochaeta aurantia subsp. aurantia (ATCC® 25082™)",
        metadataText: "Added 6/3/2024",
        uuid: "2dc5dae985a24dda",
        iconName: "jerry",
      },
      {
        name: "Polaribacter franzmannii (ATCC® 700399™)",
        metadataText: "Added 6/3/2024",
        uuid: "2923b030ec404723",
        iconName: "tom",
      },
      {
        name: "Gemella palaticanis (ATCC® BAA-58™)",
        metadataText: "Added 6/3/2024",
        uuid: "562ce66a2b97480c",
        iconName: "spike",
      },
    ];

    return genomes.map((g, idx) => {
      return (
        <GenomeTile
          name={g.name}
          metadataText={g.metadataText}
          idx={idx}
          key={g.uuid}
          uuid={g.uuid}
          iconName={g.iconName}
        />
      );
    });
  };

  render() {
    return (
      <div className="atcc-homepage">
        <div className="welcome">
          <h1>Welcome to the ATCC Genome Portal</h1>
          <p>The only authenticated reference genome database for ATCC microbes</p>
          <a className="view-genomes" href="/genomes">
            {"VIEW ALL GENOMES  "}
            <Icon icon="chevron-right" />
          </a>

          <h2>Search for a genome</h2>
          <SearchSuggestionBox performSearch={performSearch} />

          <div className="recently-updated">
            <h2>Recently published</h2>
            <div className="genome-tile-container">{this.renderGenomeTiles()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ATCCHomePage;
